import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"
import image1 from "../../images/servicesExpansion/StaffArgumentation.png"
import check from "../../images/products/check.png"
import { Link } from "gatsby"
import checkIcon from "../../images/servicesHunting/icons/check.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"

const StaffAugmentation = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const sectionData = [
    {
      key: "item1",
      text: i18n.t("pagemoreservices.StaffAugmentation.itemsAugmentation.uno"),
    },
    {
      key: "item2",
      text: i18n.t("pagemoreservices.StaffAugmentation.itemsAugmentation.dos"),
    },
    {
      key: "item3",
      text: i18n.t("pagemoreservices.StaffAugmentation.itemsAugmentation.tres"),
    },
  ]

  return (
    <div className={"whyusdiv py-6 tablet:pl-10 tablet:pr-5 pr-1"}>
      {windowWidth >= 1025 ? (
        <div className="flex mt-20 ml-16 gap-16 justify-between mr-24">
          <div className="mt-8 mb-24">
            <div className="eventRightTimeLineMobile">
              <p className="titleOurServices">
                {i18n.t("pagemoreservices.StaffAugmentation.title")}
              </p>
            </div>
            <p className="textExclusiveProducts">
              {i18n.t("pagemoreservices.StaffAugmentation.description")}
            </p>
            <p className="textExclusiveProducts">
              {i18n.t("pagemoreservices.StaffAugmentation.text")}
            </p>

            <div className="staffIcon">
              {sectionData.map(item => (
                <div className="flex ">
                  <div className="flex gap-5 mb-4">
                    <img src={checkIcon} alt="...." className="w-10 h-10" />
                    <p className="textCheck">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="contentViewMoreProducts">
              <Link to="/contact-us">
                <button
                  className="mobile:mt-4 bottomViewMoreProducts"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomViewMoreProducts">
                    {i18n.t("pagemoreservices.StaffAugmentation.botom")}
                  </p>
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-8">
            <img src={image1} alt="...." className="" />
            <div className="flex w-full justify-end pr-32">
              <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-20 px-4">
          <div className="mt-10">
            <div className="mb-10 ">
              <img src={image1} alt="...." className="" />
              <div className="flex w-full justify-center">
                <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
              </div>
            </div>
          </div>
          <div className="text-left mb-8 ">
            <p className="titleOurServices">
              {i18n.t("pagemoreservices.StaffAugmentation.title")}
            </p>
          </div>

          <p className="text_our_experienceMobile mt-4">
            {i18n.t("pagemoreservices.StaffAugmentation.description")}
          </p>

          <p className="textExclusiveProductsMobile my-5">
            {i18n.t("pagemoreservices.StaffAugmentation.text")}
          </p>

          <div className="flex flex-col gap-10 tablet:flex-row mt-6 ">
            {sectionData.map(item => (
              <div className="flex text-left gap-5">
                <img src={checkIcon} alt="...." className="w-10 h-10" />
                <p className="my-auto text-20 font-avenir-light text-gray-500">
                  {item.text}
                </p>
              </div>
            ))}
          </div>

          <div className="">
            <Link to="/contact-us">
              <button className="w-full mt-4 bg-blue-100 text-white py-2 rounded-lg">
                <p className="textBottomViewMoreProducts">
                  {i18n.t("pagemoreservices.StaffAugmentation.botom")}
                </p>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default StaffAugmentation
