import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/servicesExpansion/softwareFactory.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"
import { Link } from "gatsby"

const SoftwareFactory = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div
      className={`p-6 desktop:py-20 desktop:pl-24 desktop:pr-24 desktop:mr-1`}
    >
      {windowWidth >= 1025 ? (
        <div className="flex w-full gap-16 justify-between pr-5 bigdesktop:pr-8">
          <div className="">
            <div className="eventRightTimeLineMobile">
              <p className="titleInfoExsisAcademy">
                {i18n.t("pagemoreservices.factory.title")}
              </p>
            </div>

            <p className="textInfoExsisAcademy2 my-8">
              {i18n.t("pagemoreservices.factory.description")}
            </p>

            <div className="contentViewMoreProducts">
              <Link to="/services-software">
                <button
                  className="mobile:mt-4 bottomViewMoreProducts"
                  style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
                >
                  <p className="textBottomViewMoreProducts">
                    {i18n.t("pagemoreservices.factory.botom")}
                  </p>
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-10">
            <img src={image1} alt="...." className="" />
            <div className="flex w-full justify-end pr-32">
              <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
            </div>
          </div>
        </div>
      ) : (
        <div className=" w-full">
          <div className="mt-10 ">
            <img src={image1} alt="...." className="" />
            <div className="flex w-full justify-center">
              <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
            </div>
          </div>
          <div className="mt-8">
            <div className="text-left">
              <h2 className="text-40 text-gray-600 font-avenir-black">
                {i18n.t("pagemoreservices.factory.title")}
              </h2>
            </div>

            <p className="textInfoExsisAcademy2Mobile mt-4">
              {i18n.t("pagemoreservices.factory.description")}
            </p>
          </div>

          <div className="my-4">
            <Link to="/services-software">
              <button
                className="w-full mobile:mt-4 bottomViewMoreProducts"
                style={{ alignSelf: "left", width: "12rem", height: "3rem" }}
              >
                <p className="textBottomViewMoreProducts">
                  {i18n.t("pagemoreservices.factory.botom")}
                </p>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default SoftwareFactory
