import React from "react"

function CardSquadExsisBc({ item }) {
  return (
    <div className="flex w-full tablet:w-1/2 gap-8 items-start">
      <img className="mt-6" src={item.image} alt="icons" />
      <div className="">
        <h3 className="text-20 font-avenir-black ">{item.title}</h3>
        <p className="text-gray-500 font-avenir-light font-16 w-11/12 mt-4">
          {item.description}
        </p>
      </div>
    </div>
  )
}

export default CardSquadExsisBc
