import React, { useEffect, useState } from "react"
import imagee from "../../images/servicesHunting/image-banner-hunting.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"
import { Link } from "gatsby"
import i18n from "i18next"

function ExcepcionalHuntingExpansion() {
  const [language, setLanguage] = useState("")
  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])

  const handleUploadCVButtonClick = () => {
    // Redirigir a la URL proporcionada
    window.location.href =
      "https://www.candidatesexstaff.com.co/"
  }
  return (
    <section className="whyusdiv desktop:flex py-20 px-4 desktop:px-24 desktop:py-32 gap-24">
      <img src={imagee} alt="imgHunting" />
      <div className="tablet:hidden flex w-full justify-center mb-8">
        <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
      </div>
      <div className="text-left flex flex-col gap-6 desktop:mt-1">
        {language === "en" ? (
          <h2 className="text-40 font-avenir-black">
            E<span className="text-blue-100">x</span>cepcional IT Hunting
          </h2>
        ) : (
          <h2 className="text-40 font-avenir-black">
            IT Hunting E<span className="text-blue-100">x</span>cepcional
          </h2>
        )}

        <p className="text-gray-500 font-avenir-light text-20 ">
          {i18n.t("pagemoreservices.hunting.desct")}
        </p>
        <div className="flex flex-col tablet:flex-row gap-5">
          <Link to="/services-hunting">
            <button className="w-full tablet:w-fit text-16 font-sofiaPro-bold bg-blue-100 text-white px-10 py-2 border-2 border-blue-100  rounded-lg">
              {i18n.t("pagemoreservices.hunting.buttons.start")}
            </button>
          </Link>
          
            <button
            onClick={handleUploadCVButtonClick} 
            className="w-full tablet:w-fit text-16 font-sofiaPro-bold px-10  py-2 rounded-lg border-2 border-blue-100 text-blue-100">
              {i18n.t("pagemoreservices.hunting.buttons.upload")}
            </button>
          
        </div>
      </div>
    </section>
  )
}

export default ExcepcionalHuntingExpansion
