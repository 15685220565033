import React, { Fragment, useState, useContext } from "react"
import image1 from "../../images/products/placeholder.png"
import { LoadingContext } from "../context/LoadingContext"
import { Link } from "gatsby"
import i18n from "i18next"

import icon50 from "../../images/servicesExpansion/50.png"
import icon204 from "../../images/servicesExpansion/204.png"
import icon600 from "../../images/servicesExpansion/600.png"

import icon1 from "../../images/servicesExpansion/horizons01.png"
import icon2 from "../../images/servicesExpansion/horizons02.png"
import icon3 from "../../images/servicesExpansion/horizons03.png"
import icon4 from "../../images/servicesExpansion/horizons04.png"

import img1 from "../../images/servicesExpansion/imgMobile/image1.png"
import img2 from "../../images/servicesExpansion/imgMobile/image2.png"
import img3 from "../../images/servicesExpansion/imgMobile/image3.png"
import img4 from "../../images/servicesExpansion/imgMobile/image4.png"

const BannerExpansionServices = ({ bannerImageURL, titleHorizons }) => {
  const [dinamicHeight, setDinamicHeight] = useState(0)
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  const horizons = [
    {
      image: icon204,
      texto: "Proyectos finalizados.",
      textoEnglish: "Projects completed.",
    },
    {
      image: icon50,
      texto: "Clientes exclusivos.",
      textoEnglish: "Exclusive clients.",
    },
    {
      image: icon600,
      texto: "Profesionales de TI involucrados.",
      textoEnglish: "IT Professionals involved.",
    },
  ]

  const horizonsImagen = [icon1, icon2, icon3, icon4]
  const horizonsImgMobile = [img1, img2, img3, img4]
  return (
    <Fragment>
      <div className="container-banner-mainProducts">
        <div className="">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                <div className="text-left tablet:pl-24 text-white mb-32 tablet:mb-0">
                  {i18n.language === "en" ? (
                    <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                      E<span className="text-blue-100">x</span>pansion
                      <br />
                      <span className="text-relativo">Services</span>
                    </p>
                  ) : (
                    <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                      Nu<span className="text-blue-100">e</span>stros
                      <br />
                      <span className="text-relativo">Servicios</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="shared-banner-imageHome mobile:ml-0">
                <div className="bannerExperienceContentStyle">
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobile">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="ml-5 tablet:mx-4 mobile:my-48 text-white "
              >
                {i18n.language === "en" ? (
                  <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                    E<span className="text-blue-100">x</span>pansion
                    <br />
                    <span className="text-relativo">Services</span>
                  </p>
                ) : (
                  <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
                    Nu<span className="text-blue-100">e</span>stros
                    <br />
                    <span className="text-relativo">Servicios</span>
                  </p>
                )}
              </div>
              <div className="hidden tablet:block shared-banner-imageHomeMobile mobile:ml-0">
                <div className="bannerContentStyleMobile">
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          )}

          {windowWidth >= 1025 ? (
            <div className="mt-20 ml-12 mr-16 pb-2 desktop:flex desktop:flex-wrap desktop:px-6 bigdesktop:">
              <div className="contentWhyUs flex w-full">
                <div className="eventRightTimeLineMobile divInfoServices">
                  <div className="flex">
                    <p className="titleDecoration">{titleHorizons}</p>
                  </div>
                </div>
                <div className="divInfoExsisAcademy">
                  <div className="mt-4">
                    <p className="text_Expanding">
                      {i18n.t("pagemoreservices.expansionServices.description")}
                    </p>
                  </div>

                  <div className="flex mt-10">
                    {horizons.map((item, i) => {
                      return (
                        <div key={i*7} className="mr-6 ">
                          <img
                            src={item.image}
                            alt="...."
                            className="mr-auto ml-auto"
                          />
                          <p className="text_Expanding2 mt-4">
                            {i18n.language === "en"
                              ? item.textoEnglish
                              : item.texto}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="w-full ml-8 mt-12 flex justify-between bigdesktop:pr-8 bigdesktop:mb-32">
                {horizonsImagen.map((item, i) => {
                  return (
                    <div key={i*4} className="mr-6 bigdesktop:mr-0 ">
                      <img src={item} alt="...." className="w-full" />
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 px-5">
              <div className="mb-10">
                {i18n.language === "en" ? (
                  <h2 className="text-left font-avenir-black text-40 text-gray-600 leading-tight">
                    E<span className="text-blue-100">x</span>panding{" "}
                    {i18n.t("pagemoreservices.expansionServices.title")}
                  </h2>
                ) : (
                  <h2 className="text-left font-avenir-black text-40 text-gray-600 leading-tight">
                    {i18n.t("pagemoreservices.expansionServices.title")}
                  </h2>
                )}
                <p className="text_our_experienceMobile mt-8">
                  {i18n.t("pagemoreservices.expansionServices.description")}
                </p>

                <div className="mt-10 flex items-center">
                  {horizons.map((item, i) => {
                    return (
                      <div key={i*3} className="flex flex-col">
                        <img src={item.image} alt="...." className="mx-auto" />
                        <p className="mt-4 font-avenir-light text-16 text-gray-600">
                          {i18n.language === "en"
                            ? item.textoEnglish
                            : item.texto}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="mt-20">
                {horizonsImgMobile.map((item, i) => {
                  return (
                    <div key={i*4} className="mb-4">
                      <img src={item} alt="...." className="w-full" />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

BannerExpansionServices.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default BannerExpansionServices
