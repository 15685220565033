import React, { useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import imgen1 from "../../images/servicesExpansion/iconsExsisBlockchain/SmartContractIcon.png"
import imgen2 from "../../images/servicesExpansion/iconsExsisBlockchain/DeFisIcon.png"
import imgen3 from "../../images/servicesExpansion/iconsExsisBlockchain/BlockchainGameDevelopmentIcon.png"
import imgen4 from "../../images/servicesExpansion/iconsExsisBlockchain/SwapsIcon.png"
import imgen5 from "../../images/servicesExpansion/iconsExsisBlockchain/DecentralizedAutonomousOrganizationIcon.png"
import imgen6 from "../../images/servicesExpansion/iconsExsisBlockchain/ExchangesIcon.png"
import CardSquadExsisBc from "./CardSquadExsisBc"
import { Link } from "gatsby"
import i18n from "i18next"

function ExsisBlockchainExpasion() {
  const { windowWidth } = useContext(LoadingContext)
  const squadExsis = [
    {
      title: i18n.t("pagemoreservices.exsisBlockchain.stages.stage1.title"),
      description: i18n.t(
        "pagemoreservices.exsisBlockchain.stages.stage1.desc"
      ),
      image: imgen1,
    },
    {
      title: i18n.t("pagemoreservices.exsisBlockchain.stages.stage4.title"),
      description: i18n.t(
        "pagemoreservices.exsisBlockchain.stages.stage4.desc"
      ),

      image: imgen2,
    },
    {
      title: i18n.t("pagemoreservices.exsisBlockchain.stages.stage2.title"),
      description: i18n.t(
        "pagemoreservices.exsisBlockchain.stages.stage2.desc"
      ),

      image: imgen3,
    },
    {
      title: i18n.t("pagemoreservices.exsisBlockchain.stages.stage5.title"),
      description: i18n.t(
        "pagemoreservices.exsisBlockchain.stages.stage5.desc"
      ),
      image: imgen4,
    },
    {
      title: i18n.t("pagemoreservices.exsisBlockchain.stages.stage3.title"),
      description: i18n.t(
        "pagemoreservices.exsisBlockchain.stages.stage3.desc"
      ),

      image: imgen5,
    },
    {
      title: i18n.t("pagemoreservices.exsisBlockchain.stages.stage6.title"),
      description: i18n.t(
        "pagemoreservices.exsisBlockchain.stages.stage6.desc"
      ),
      image: imgen6,
    },
  ]

  const mobileSquadExsis = [
    squadExsis[0],
    squadExsis[2],
    squadExsis[4],
    squadExsis[1],
    squadExsis[3],
    squadExsis[5],
  ]

  return (
    <section className="text-left flex flex-col gap-5 py-20 px-4 tablet:p-24 pb-32">
      <h2 className="text-gray-600 text-40 font-avenir-black">
        {i18n.t("pagemoreservices.exsisBlockchain.title")}
      </h2>
      <p className="text-gray-500 text-20 font-avenir-heavy tablet:w-7/12">
        {i18n.t("pagemoreservices.exsisBlockchain.description")}
      </p>
      <p className="text-gray-500 font-avenir-light text-20 tablet:w-2/3">
        {i18n.t("pagemoreservices.exsisBlockchain.desct")}
      </p>
      <Link to="/services-blockchain">
        <button className="w-full tablet:w-fit px-10 py-2 text-white text-16 font-sofiaPro-bold rounded-lg bg-blue-100">
          {i18n.t("pagemoreservices.exsisBlockchain.buttom")}
        </button>
      </Link>
      <div className="flex flex-col tablet:flex-row gap-y-6 tablet:flex-wrap py-10">
        {windowWidth >= 640
          ? squadExsis.map((item, index) => (
              <CardSquadExsisBc key={index} item={item} />
            ))
          : mobileSquadExsis.map((item, index) => {
              return <CardSquadExsisBc key={index} item={item} />
            })}
      </div>
    </section>
  )
}

export default ExsisBlockchainExpasion
