import React, { useState, useEffect, useRef } from "react"
import i18n from "i18next"
import columnImageRight from "../images/servicesExpansion/ImagenBanner.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import BannerExpansionServices from "../components/expansionServices.js/BannerExpansionServices"
import StaffAugmentation from "../components/expansionServices.js/StaffAugmentation"
import SoftwareFactory from "../components/expansionServices.js/SoftwareFactory"
import ExcepcionalHuntingExpansion from "../components/expansionServices.js/ExcepcionalHuntingExpansion"
import ExsisBlockchainExpasion from "../components/expansionServices.js/ExsisBlockchainExpasion"

const ServiceExpansion = () => {
  const [titleHorizons, setTitleHorizons] = useState([])

  useEffect(() => {
    if (i18n.language === "en") {
      setTitleHorizons([
        <div className="">
          <h3 className="">
            <span className="textOurExperience2 flex">
              <p className="textExpanding">E</p>
              <p className="textExpandingBlue">x</p>
              <p className="textExpanding">panding Horizons:</p>
            </span>
            <span className="textExpanding">
              Leveraging Technology for a Better World
            </span>
          </h3>
        </div>,
      ])
    } else {
      setTitleHorizons([
        <div className="">
          <h3 className="">
            <span className="textExpanding">
              Creemos en el poder de la tecnología para conseguir un mundo
              mejor.
            </span>
          </h3>
        </div>,
      ])
    }
  }, [i18n.language])

  return (
    <Layout>
      <SEO
        title={"Services Expansion | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />

      <BannerExpansionServices
        bannerImageURL={columnImageRight}
        titleHorizons={titleHorizons}
      ></BannerExpansionServices>

      <StaffAugmentation />
      <SoftwareFactory />
      <ExcepcionalHuntingExpansion />
      <ExsisBlockchainExpasion />
    </Layout>
  )
}

export default withNamespaces()(ServiceExpansion)
